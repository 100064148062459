import { useMemo } from "react";
import { WelcomeWrapper } from "./style";
import branch01 from "../assets/branchImg/64256760d02b341d2ae259c7.png"; //죽전점
import branch02 from "../assets/branchImg/656d38568a092b0b83012020.png"; //계산점
import branch03 from "../assets/branchImg/656f470358cc8bbf42859834.png"; //독산점
import branch04 from "../assets/branchImg/656f471b58cc8bbf4285986a.png"; //구로점
import branch05 from "../assets/branchImg/6575bdb280a4f697f82cbb07.png"; //석촌점
import branch06 from "../assets/branchImg/6575bdc080a4f697f82cbb3d.png"; //삼전점
import branch07 from "../assets/branchImg/6575bdcc80a4f697f82cbb73.png"; //석촌고분점
import branch08 from "../assets/branchImg/65786d907a8773ec6440a131.png"; //강동구청점
import branch09 from "../assets/branchImg/657eae876ad3e51c888eccd4.png"; //동탄점
import branch10 from "../assets/branchImg/6582f91e6ad3e51c888f0b38.png"; //신갈점
import branch11 from "../assets/branchImg/6582fc2c6ad3e51c888f0c03.png"; //까치산점
import branch12 from "../assets/branchImg/658a4e75de26b7792321965a.png"; //반석점
import branch13 from "../assets/branchImg/658ae508de26b7792321a6cd.png"; //유성온천점
import branch14 from "../assets/branchImg/658ae516de26b7792321a703.png"; //월평점
import branch15 from "../assets/branchImg/658ae521de26b7792321a739.png"; //둔산점
import branch16 from "../assets/branchImg/65cb1a5f934053ede3d9cd85.png"; //상록수역점

const Welcome = ({ branchId }) => {
  const branchImg = useMemo(() => {
    switch (branchId) {
      //죽전
      case "64256760d02b341d2ae259c7":
        return branch01;
      //계산
      case "656d38568a092b0b83012020":
        return branch02;
      //독산
      case "656f470358cc8bbf42859834":
        return branch03;
      //구로
      case "656f471b58cc8bbf4285986a":
        return branch04;
      //석촌
      case "6575bdb280a4f697f82cbb07":
        return branch05;
      //삼전
      case "6575bdc080a4f697f82cbb3d":
        return branch06;
      //고분
      case "6575bdcc80a4f697f82cbb73":
        return branch07;
      //강동
      case "65786d907a8773ec6440a131":
        return branch08;
      //동탄
      case "657eae876ad3e51c888eccd4":
        return branch09;
      //신갈
      case "6582f91e6ad3e51c888f0b38":
        return branch10;
      //까치산
      case "6582fc2c6ad3e51c888f0c03":
        return branch11;
      //반석
      case "658a4e75de26b7792321965a":
        return branch12;
      //유성온천
      case "658ae508de26b7792321a6cd":
        return branch13;
      //월평
      case "658ae516de26b7792321a703":
        return branch14;
      //둔산
      case "658ae521de26b7792321a739":
        return branch15;
      //상록수역
      case "65cb1a5f934053ede3d9cd85":
        return branch16;
      // DEFAULT
      default:
        return branch01;
    }
  }, [branchId]);

  return (
    <WelcomeWrapper>
      <img src={branchImg} alt="지점메인" height="100%" />
    </WelcomeWrapper>
  );
};

export default Welcome;
